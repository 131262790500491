import { ContentChangeListener, ContentServiceV1 } from '@oneaudi/content-service';
import React, { ReactElement, useState } from 'react';
import type { Logger } from '@feature-hub/logger';
import { FaqsProps } from '../components/Faqs/index';
import { ContentContext } from './ContentContext';
import { EditorJsonContent, FalconContent } from './ContentInterfaces';

const mapHeadlessContent = (content: FalconContent): FaqsProps => {
  const { title, headingLevel, faqs } = content.fields;

  const appContent: FaqsProps = {
    title,
    headingLevel,
    faqs: faqs.map((faq) => ({ ...faq.fields })),
  };
  return appContent;
};

const mapFaceContent = (content: EditorJsonContent): FaqsProps => {
  const appContent: FaqsProps = { ...content };
  return appContent;
};

export const ContentContextProvider: React.FC<{
  contentService: ContentServiceV1;
  loggerService?: Logger;
  children?: ReactElement;
}> = ({ contentService, children, loggerService }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [rawContent, setRawContent] = useState(contentService.getContent());
  contentService.onContentChange(setRawContent as ContentChangeListener);
  let content: FaqsProps;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (rawContent.__type === 'aem-headless') {
    const falconContent = rawContent as FalconContent;
    content = mapHeadlessContent(falconContent);
    loggerService?.info('aem-headless-content:', falconContent);
  } else {
    const editorJsonContent = rawContent as EditorJsonContent;
    content = mapFaceContent(editorJsonContent);
  }

  /* eslint-disable react/jsx-no-constructed-context-values */
  return <ContentContext.Provider value={{ content }}>{children}</ContentContext.Provider>;
};

ContentContextProvider.displayName = 'ContentContextProvider';
