/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React, { useContext } from 'react';
import { withAudiDesignSystemLight } from '@oneaudi/narown-utils-ui';
import { withFocusReset } from './components/Faqs/withFocusReset';
import { Faqs } from './components/Faqs';
import { ContentContext } from './context';

const Container = withAudiDesignSystemLight(Faqs);

const FeatureApp: React.FC = () => {
  const context = useContext(ContentContext);
  const { content } = context;

  return (
    <Container title={content.title} headingLevel={content.headingLevel} faqs={content.faqs} />
  );
};

export default withFocusReset(FeatureApp);
