/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import parse, { HTMLReactParserOptions, DOMNode, domToReact } from 'html-react-parser';
import { Text } from '@audi/audi-ui-react';
import { StyledAnchor, UnderlinedText } from './styles';

const options: HTMLReactParserOptions = {};

// custom type because their TS types are not good
export type HtmlParserNode = {
  data: string;
  children: DOMNode[];
  name: string;
  type: string;
  attribs: { href: string; style: string };
};

const isBold = (name: string): boolean => {
  if (name === 'strong' || name === 'b') return true;
  return false;
};

const isUnderline = (name: string, attribs: { style: string }): boolean => {
  if (
    name === 'span' &&
    (attribs?.style === 'text-decoration:underline;' ||
      attribs?.style === 'text-decoration: underline;')
  )
    return true;
  if (name === 'u') return true;
  return false;
};

export const replace = ({
  type,
  data,
  children,
  attribs,
  name,
}: HtmlParserNode): React.ReactElement | string => {
  // handle any loose text elements
  if (type === 'text') {
    return data;
  }

  if (name === 'p') {
    return (
      <Text as="p" spaceStackStart="m">
        {domToReact(children, options)}
      </Text>
    );
  }

  if (isBold(name)) {
    return (
      <Text as="strong" weight="bold">
        {domToReact(children, options)}
      </Text>
    );
  }

  if (isUnderline(name, attribs)) {
    return <UnderlinedText>{domToReact(children, options)}</UnderlinedText>;
  }

  if (name === 'span') {
    return (
      <Text as="span" spaceStackStart="m">
        {domToReact(children, options)}
      </Text>
    );
  }

  if (name === 'a') {
    return (
      <StyledAnchor target="_blank" className="faqs-anchor" href={attribs.href}>
        {domToReact(children, options)}
      </StyledAnchor>
    );
  }

  if (name === 'br') {
    return <br />;
  }

  // returning fragments prevents any other type of HTML from coming through
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

// @ts-ignore - HtmlParser's Typescript is not very good
options.replace = replace;

export const parser = (
  (opt: HTMLReactParserOptions) => (html: string) =>
    parse(html, opt)
)(options);
