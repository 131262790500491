import {
  getBackgroundLevel,
  getResponsiveSpacing,
  getThemeGreys,
  getUiColor,
  injectTypography,
} from '@oneaudi/narown-utils-ui';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${getResponsiveSpacing('xxl')};

  p:first-child {
    margin-block-start: 0;
  }

  .faqs-container * {
    ${(props) => injectTypography('copy1', props)}
  }
`;

export const InnerContainer = styled.div`
  max-width: 1920px;
  width: 100%;

  // styles for backwards compatibility start, remove when adopting current ADS colors
  .no-background {
    background-color: unset;
  }

  > div > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    background-color: ${getBackgroundLevel(0)};
  }

  > div > div {
    background-color: ${getBackgroundLevel(3)};
  }
  // styles for backwards compatibility end
`;

export const UnderlinedText = styled.span`
  ${(props) => injectTypography('copy1', props)};
  text-decoration: underline;
`;

export const StyledAnchor = styled.a`
  ${(props) => injectTypography('copy1', props)};
  line-height: 1.67;
  color: ${getUiColor('secondary')};
  text-decoration: none;
  border-bottom: 1px solid ${getThemeGreys(60)};
  font-weight: bold;

  :hover {
    color: ${getUiColor('primary')};
    border-bottom: 1px solid ${getThemeGreys(100)};
  }
`;
