import { createContext } from 'react';
import { FaqsProps } from '../components/Faqs';

export interface ContentContextState {
  content: FaqsProps;
}

export const ContentContext = createContext<ContentContextState>({
  content: {
    faqs: [],
    title: '',
    headingLevel: 'h2',
  },
});
