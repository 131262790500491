import { ThemeProvider, Accordion, AccordionSection, Text } from '@audi/audi-ui-react';
import React, { useEffect, useMemo, useState } from 'react';
import { UeElement } from '@oneaudi/falcon-tools';

import {
  HeadingLevel,
  clampAccordionHeadingLevel,
  clampTitleHeadingLevel,
} from './clampHeadingLevel';
import { parser } from './parser';
import { Container, InnerContainer } from './styles';

export type FaqConfig = {
  question: string;
  answer: string;
};
export interface FaqsProps {
  // eslint-disable-next-line react/require-default-props
  title?: string;
  headingLevel: HeadingLevel;
  faqs: FaqConfig[];
}

export const Faqs: React.FC<FaqsProps> = ({ title, faqs = [], headingLevel = 'h2' }) => {
  const [isServer, setServer] = useState(true);
  useEffect(() => {
    setServer(false);
  }, []);
  /* h1 - h5 */
  const titleHeadingLevel = clampTitleHeadingLevel(headingLevel);
  /* h2 - h6 */
  const accordionHeadingLevel = clampAccordionHeadingLevel(headingLevel, Boolean(title));

  const accordionSections = useMemo(() => {
    return isServer
      ? null
      : faqs.map((faq) => (
          <AccordionSection
            key={crypto.randomUUID()}
            headingLevel={accordionHeadingLevel}
            id={crypto.randomUUID()}
            label={faq.question}
          >
            {(parser as (htmlString: string) => JSX.Element)(faq.answer)}
          </AccordionSection>
        ));
  }, [faqs, isServer]);

  return (
    <ThemeProvider>
      <Container className="faq-container">
        <InnerContainer>
          {title && (
            <div>
              <UeElement type="text" property="title">
                <Text
                  // for backwards compatibility with ADS v1. Remove when settling for v2 colors
                  className="no-background"
                  as={titleHeadingLevel}
                  spaceStackEnd="xl"
                  weight="bold"
                  variant="order3"
                >
                  {title}
                </Text>
              </UeElement>
            </div>
          )}
          {accordionSections ? <Accordion>{accordionSections}</Accordion> : null}
        </InnerContainer>
      </Container>
    </ThemeProvider>
  );
};

Faqs.displayName = 'Faqs';
